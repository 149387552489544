/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router/index.js";
import store from "./store";
import ElementPlus from "element-plus";
import frLocale from 'element-plus/es/locale/lang/fr';

// Synfusion license
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJiW39acXBURWNdWEQ=');

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
var Emitter = require('tiny-emitter');
import { PublicClientApplication } from "@azure/msal-browser";

import "@/core/plugins/prismjs";
import "bootstrap";

const app = createApp(App);

app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$msalInstance = new PublicClientApplication(
    store.state.msalConfig
);
// eslint-disable-next-line
app.config.globalProperties.$emitter = new Emitter();

app.use(store);
app.use(router);
app.use(ElementPlus, {
    locale: frLocale,
});

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.mount("#app");
